<script lang="ts" setup>
import type { OfferFull, OfferMedium } from '~/types/Catalog';

const props = defineProps<{ offer: OfferFull | OfferMedium }>();

const authStore = useAuthStore();

const warehouses = computed(() =>
  props.offer.offer_balances
    ?.map((w) => ({
      ...w,
      name: w.warehouse.name.match(/\(([^)]+)\)/)?.[1] || '',
    }))
    .filter((w) => w.warehouse.show && w.warehouse.name)
    .sort((a, b) => a.warehouse.id - b.warehouse.id),
);
</script>

<template>
  <section
    v-if="authStore.accessRights?.can_view_balance && offer.offer_balances"
    class="text-sm"
  >
    <p class="mb-1 italic text-hell-black-80">Наличие (для&nbsp;сотрудника)</p>

    <ul class="flex flex-col">
      <li
        v-for="warehouse in warehouses"
        :key="warehouse.warehouse.id"
      >
        <div class="flex items-start gap-1.5">
          <div
            :class="warehouse.balance ? 'bg-hell-green' : 'bg-hell-red'"
            class="rounded-full w-1.5 h-1.5 mt-[7px] flex-shrink-0"
          />

          <div class="leading-5">
            {{ warehouse.name }}:
            <span class="font-bold">{{ warehouse.balance }}</span>
          </div>
        </div>
      </li>

      <li v-if="offer.popularity_rank">
        <div class="flex items-center gap-[3px] mt-2 -ml-[3px]">
          <SvgoStar
            class="text-xs"
            filled
          />

          <div class="leading-5">Ранг: {{ offer.popularity_rank }}</div>
        </div>
      </li>
    </ul>
  </section>
</template>
